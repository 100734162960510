export const dataScope_2 = [
  ["Jan", 350],
  ["Feb", 440],
  ["Mar", 290],
  ["Apr", 360],
  ["May", 420],
  ["Jun", 423],
  ["Jul", 460],
  ["Aug", 405],
  ["Sep", 350],
  ["Oct", 300],
  ["Nov", 420],
  ["Dec", 460],
];
export const dataRenewables_2 = [
  ["Jan", 210],
  ["Feb", 290],
  ["Mar", 190],
  ["Apr", 230],
  ["May", 210],
  ["Jun", 240],
  ["Jul", 290],
  ["Aug", 280],
  ["Sep", 190],
  ["Oct", 290],
  ["Nov", 199],
  ["Dec", 215],
];
export const dataTarget_2 = [
  ["Jan", 60],
  ["Feb", 65.9],
  ["Mar", 65.51],
  ["Apr", 41.85],
  ["May", 50],
  ["Jun", 56.73],
  ["Jul", 63.04],
  ["Aug", 69.13],
  ["Sep", 54.28],
  ["Oct", 96.66],
  ["Nov", 47.38],
  ["Dec", 46.73],
];
export const dataMarker = [
  ["Jan", 100],
  ["Feb", 100],
  ["Mar", 100],
  ["Apr", 100],
  ["May", 100],
  ["Jun", 100],
  ["Jul", 100],
  ["Aug", 100],
  ["Sep", 100],
  ["Oct", 100],
  ["Nov", 100],
  ["Dec", 100],
];
